<template>
    <section class="view3d-page section p-0">
      <div class="overflow-scroll">
        <section class="section content p-5">
          <div class="title is-5 mb-3 pb-3">{{ info.title }}</div>
          <div class="view-content">
            <div id="wrapper-el" class="view3d-wrapper view3d-square">
              <canvas class="view3d-canvas"></canvas>
            </div>
          </div>
        </section>
      </div>
      
      <div class="link-buttons px-4 pb-2 columns is-mobile" v-if="info.buttons">
      <div v-for="(button,index) in info.buttons"
        class="column"
        :class="buttonClass(index)"
        :key="button.id">
        <a :href="button.link"
          class="button is-fullwidth is-primary is-outlined"
          target="_blank">
          <d-icon v-if="button.icon" :pack="button.icon.pack" :icon="button.icon.name" size="is-small" class="mr-1">
          </d-icon>
          {{ button.label }}
        </a>
      </div>
    </div>
  
    </section>
  </template>
  
  <script>
  // TODO: implementar version vue
  // "@egjs/vue-view3d"
  import View3D, { ARButton } from "@egjs/view3d";
  import "@egjs/view3d/css/view3d-bundle.min.css";
  
  export default {
    components: {
    },
    props: {
      info: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        view3D: null
      };
    },
    computed: {
    },
    methods: {
      buttonClass(index) {
        if(this.info.buttons.length == 3 && index == 0) return 'is-full-mobile';
        if(this.info.buttons.length == 4) return 'is-half-mobile';
        return false
      }
    },
    mounted() {
      console.log(this.info)
      this.view3D = new View3D("#wrapper-el", {
        src: this.info.model.url,
        iosSrc: this.info.iosModel?.url,
        poster: this.info.poster?.url,
        arPriority: [
          "webAR",
          "sceneViewer",
          "quickLook"
        ],
        plugins: [
          new ARButton({
            availableText: this.$t('system.viewInAR'),
          })
        ]
      });
  
    },
  };
  </script>
  <style lang="scss" scoped>
  @import '@/styles/variables.scss';
  
  .view3d-wrapper {
    width: 100%;
  }
  .view3d-page {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  
    .overflow-scroll {
      flex-grow: 1;
      height: 1px;
    }
    .title {
      border-bottom: solid 1px #E0E0E0;
    }
  
    img {
      object-fit: cover;
    }
  
    .column {
      padding: .5rem;
    }
    .columns:last-child {
      margin-bottom: 0;
    }
    .link-buttons {
      flex-wrap: wrap;
      background: $white-bis;
      box-shadow: 0px -6px 6px $white-bis;
    }
  
  }
  </style>